<template>
  <div>
    <div class="page" :style="titleStatus == 1 || titleStatus == 2?{height: 'calc(100vh - 144px)'}:{height: 'calc(100vh - 91px)'}">
      <!-- <div class="create">
        <el-button plain type="normal" size="mini" @click="close"
          >返回</el-button
        >
      </div> -->

      <!-- 添加 编辑 -->
      <div class="box1" v-if="titleStatus == 1 || titleStatus == 2">
        <div class="tableRight">
          <el-form
            ref="ruleForm"
            class="inline:false"
            label-position="left"
            :model="form"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item label="安检周期" prop="periodId">
              <el-select
                v-model="form.periodId"
                placeholder="请选择"
                style="width: 400px"
                filterable
                size="small"
                @change="periodChange"
              >
                <el-option
                  v-for="item in periodLists"
                  :key="item.id"
                  :label="item.periodName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检名称" prop="taskName">
              <el-input
                v-model="form.taskName"
                size="small"
                style="width: 400px"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户分类" prop="userType">
              <el-radio-group
                v-model="form.userType"
                @change="getTemplate"
                style="width: 800px"
              >
                <el-radio
                  v-for="item in userTypes"
                  :key="item.id"
                  style="margin-top: 10px"
                  :label="item.id.toString()"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="采集模板" prop="tplId">
              <el-select
                v-if="colList.length > 0"
                v-model="form.tplId"
                placeholder="请选择"
                style="width: 300px"
                filterable
              >
                <el-option
                  v-for="item in colList"
                  :key="item.id"
                  :label="item.tplName"
                  :value="parseInt(item.id)"
                >
                </el-option>
              </el-select>
              <template v-else>选择用户分类后自动展示</template>
            </el-form-item>
            <el-form-item label="安检模板" prop="scuTplId">
              <el-select
                v-if="checkList.length > 0"
                v-model="form.scuTplId"
                placeholder="请选择"
                style="width: 300px"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in checkList"
                  :key="item.id"
                  :label="item.tplName"
                  :value="parseInt(item.id)"
                >
                </el-option>
              </el-select>
              <template v-else>选择用户分类后自动展示</template>
            </el-form-item>
            <el-form-item label="安检用户" prop="userIds">
              <div class="informFlex" style="width: 80%">
                <div  style="width: 30%;height:380px;overflow: auto">
                  <el-tree
                      ref="dataTree"
                      :data="dataTree"
                      :props="defaultProps"
                      node-key="id"
                      show-checkbox
                      @node-click="handleNodeClick"
                      @check-change="handleCheckChange"
                      :default-checked-keys="checkedKeys"
                      :default-expanded-keys="expandedKeys"
                  >
                  </el-tree>
                </div>
                <div class="table-contain">
                  <span>总户数：{{ this.form.userIds.length }}</span>
                  <el-table
                    :data="tableData"
                    style="width: 100%"
                    :border="true"
                    height="340"
                    :header-cell-style="{ backgroundColor: '#F9FAFE' }"
                  >
                    <el-table-column prop="addrName" label="片区">
                    </el-table-column>
                    <el-table-column
                      prop="userCount"
                      label="用户数量"
                      width="130"
                    ></el-table-column>
                  </el-table>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              label="安检员"
              prop="inspectorIds"
              style="margin-top: 24px"
            >
              <el-select
                v-model="form.inspectorIds"
                placeholder="请选择"
                style="width: 400px"
                multiple
                filterable
                size="small"
              >
                <el-option
                  v-for="item in checkerList"
                  :key="item.id"
                  :label="item.realname"
                  :value="parseInt(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="任务时间"
              prop="startTime"
              style="margin-top: 24px"
            >
              <el-date-picker
                v-model="times"
                type="daterange"
                range-separator="至"
                placeholder="请选择时间段"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="setDate"
                size="small"
                style="width: 400px"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 查看 -->
      <div class="box1" v-else-if="titleStatus == 3">
        <el-form class="watchForm" label-width="100px">
          <el-form-item label="安检周期">{{ form.periodName }}</el-form-item>
          <el-form-item label="用户分类">{{ form.userTypeName }}</el-form-item>
          <el-form-item label="采集模板">{{ form.templateName }}</el-form-item>
          <el-form-item label="安检模板">{{
            form.scuTemplateName
          }}</el-form-item>
          <el-form-item label="安检用户">
            <span>总户数：{{ form.houseCount }}</span>
            <el-table
              :data="tableData"
              style="width: 100%"
              max-height="400"
              :border="true"
              :header-cell-style="{ backgroundColor: '#F9FAFE' }"
            >
              <el-table-column prop="addrName" label="片区" align="center">
              </el-table-column>
              <el-table-column label="用户数量" width="130" align="center">
                <template slot-scope="{ row }">
                  <span
                    style="color: #1082ff; cursor: pointer"
                    @click="watchUser(row)"
                    >{{ row.userCount }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="安检员">{{ form.inspectorNames }}</el-form-item>
          <el-form-item label="任务时间"
            >{{ form.startTime }}-{{ form.endTime }}</el-form-item
          >
        </el-form>
      </div>
      <!-- 查看用户 -->
      <el-dialog
        :visible.sync="userDialog"
        :close-on-click-modal="false"
        @close="closeUser"
        width="80%"
      >
        <el-table
          :data="tableDataUser"
          style="width: 100%"
          :border="true"
          height="500"
          :header-cell-style="{ backgroundColor: '#F9FAFE' }"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="residentName"
            label="户主姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="户主电话"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="userTypeName"
            label="用户分类"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="addrName"
            label="片区"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="address"
            label="地址"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sheetSteel"
            label="表编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tableTypeName"
            label="燃气表类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tableDirectionName"
            label="表向"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tableVenderName"
            label="燃气表厂家"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="tableTitles"
            label="表封号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="openDate"
            label="开户日期"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="receiveStove"
            label="所接炉具"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[10, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="userDialog = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- <el-form-item > -->
    <el-button v-if="titleStatus == 1 || titleStatus == 2" type="primary" style="margin-left: 157px" @click="confirm"
      >确 定</el-button
    >
    <!-- </el-form-item> -->
  </div>
</template>

<script>
import {
  dict_selectDictListByPid,
  aaumTask_showTempletByUserType,
  aaumTask_findUserTreeEcho,
  aaumTask_taskInsertOrUpdate,
  AaumArchive_inspectorDropDown,
  aaumTask_selectTaskById,
  aaumTask_findAddrByAaumUsers,
  aaumTask_findUsersByTaskIdAndAddrId,
} from "../../../RequestPort/securityTask/unResident";
import {
  arumTaskFindAddrByArumUsers,
  arumTaskFindUsersByTaskIdAndAddrId,
  arumTaskSelectTaskById,
  arumTaskTaskInsertOrUpdate,
  arumTaskFindUserTreeEcho,
  arumTaskTemplatePulldown,
  arumTaskScuTemplatePulldown,
  periodList
} from "../../../RequestPort/selectQuery/residentRequest.js";
import {selectRepeat} from "../../../RequestPort/dict/cycle";
export default {
  data() {
    return {
      search: {
        current: 1,
        size: 100,
      },
      total: 0,
      form: {
        periodId: null, // 安检周期id
        taskName: "", //任务名称
        inspectorIds: [], //安检员id数组
        scuTplId: "", //安检模板id
        tplId: "", //采集模板id
        userIds: "", //用户id数组
        userType: "",
        startTime: "", //任务时间(开始时间)
        endTime: "", //任务时间(结束时间)
      },
      userTypes: [],
      checkerList: [],
      dataTree: [
        // {
        //   label: "一级 1",
        //   children: [
        //     {
        //       label: "二级 1-1",
        //       children: [
        //         {
        //           label: "三级 1-1-1",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      defaultProps: {
        children: "children",
        label: "name",
        disabled: 'disable'
      },
      tableData: [],
      tableDataUser: [],
      tableDataNum: {},
      rules: {
        periodId: {
          required: true,
          message: "请选择安检周期",
          trigger: "change",
        },
        taskName: {
          required: true,
          message: "请输入安检名称",
          trigger: "blur",
        },
        userType: {
          required: true,
          message: "请选择用户分类",
          trigger: "change",
        },
        userIds: {
          required: true,
          message: "请选择安检用户",
          trigger: "change",
        },
        inspectorIds: {
          required: true,
          message: "请选择安检员",
          trigger: "change",
        },
        tplId: {
          required: true,
          message: "请先添加采集模板",
          trigger: "change",
        },
        scuTplId: {
          required: true,
          message: "请先添加安检模板",
          trigger: "change",
        },
        startTime: {
          required: true,
          message: "请选择任务时间",
          trigger: "change",
        },
      },
      times: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      userDialog: false,
      addrId: "",
      checkedKeys: [],
      expandedKeys: [],
      colList: [], // 采集模板下拉
      checkList: [], // 安检模板下拉
      titleStatus:1,
      periodLists: [], // 安检周期下拉
    };
  },
  // props: ["titleStatus"],
  mounted() {
      if(this.$route.query.titleStatus) {
        this.titleStatus = this.$route.query.titleStatus
      }
      this.getDesc(this.$route.query.id)
    
    this.getOption();
    // this.getPeriod();
  },
  methods: {
    // 获取是否可重复
    async getRepeated() {
        let data = selectRepeat().then(res => {
            return res.data === 1 ? true : false
        })
        return Promise.resolve(data)
    },
    async getPeriod() { // 安检周期下拉
      let data = periodList().then(res => {
        this.periodLists = res.data
        
        return res.data
      })
      return Promise.resolve(data)
    },
    periodChange() { // 安检周期变化重新请求用户树
      this.getRepeated().then(data => {
        if (!data) {
          arumTaskFindUserTreeEcho({ userType: this.form.userType, periodId: this.form.periodId }).then((res) => {
            this.dataTree = res.data.data;
            this.checkedKeys = []
            this.expandedKeys = res.data.defaultExpandedKeys;
            this.form.userIds = [];
            this.tableData = [];
            this.$refs.ruleForm.clearValidate("userIds");
          });
        }
      })
    },
    getOption() {
      dict_selectDictListByPid({ pid: 732 }).then((res) => {
        this.userTypes = res.data;
      });

      AaumArchive_inspectorDropDown({}).then((res) => {
        this.checkerList = res.data;
      });
    },
    getTemplate(val) {
      arumTaskTemplatePulldown({ userType: val }).then((res) => {
        this.form.tplId = "";
        if (res.data) {
          this.colList = res.data;
        } else {
          this.colList = [];
        }
        // this.$refs.ruleForm.validateField("templateName");
      });
      arumTaskScuTemplatePulldown({ userType: val }).then((res) => {
        this.form.scuTplId = "";
        if (res.data) {
          this.checkList = res.data;
        } else {
          this.checkList = [];
        }
        this.$refs.ruleForm.validateField(["scuTplId", "tplId"]);
      });
      arumTaskFindUserTreeEcho({ userType: this.form.userType, periodId: this.form.periodId }).then((res) => {
        this.dataTree = res.data.data;
        this.expandedKeys = res.data.defaultExpandedKeys;
        this.form.userIds = [];
        this.tableData = [];
        this.$refs.ruleForm.clearValidate("userIds");
      });
    },
    getDesc(id) {
      this.$nextTick(function () {
        if (this.titleStatus == 1) {
          this.getPeriod().then(data => {
            // arumTaskFindUserTreeEcho({periodId: this.form.periodId}).then((res) => {
            //   this.dataTree = res.data.data;
            //   this.expandedKeys = res.data.defaultExpandedKeys;
            //   if (this.$refs.ruleForm) {
            //     this.$refs.ruleForm.resetFields();
            //   }
            //   !this.form.periodId ? this.form.periodId = data[0].id : ''
            // });
            if (this.$refs.ruleForm) {
              this.$refs.ruleForm.resetFields();
            }
            !this.form.periodId ? this.form.periodId = data[0].id : ''
          })
        } else {
            arumTaskSelectTaskById({ taskId: id }).then((res) => {
              if (this.titleStatus == 2) {
                this.form.userType = res.data.userType.toString();
                this.form.periodId = String(res.data.periodId);
                
                this.getPeriod()
                arumTaskTemplatePulldown({ userType: res.data.userType }).then((res) => {
                  this.colList = res.data ? res.data : []
                });
                arumTaskScuTemplatePulldown({ userType: res.data.userType }).then((res) => {
                  this.checkList = res.data ? res.data : []
                });
                
                this.form.periodName = res.data.periodName;
                this.form.templateName = res.data.templateName;
                this.form.taskName = res.data.taskName;
                this.form.tplId = Number(res.data.tplId);
                this.form.scuTplId = Number(res.data.scuTplId);
                arumTaskFindUserTreeEcho({
                  taskId: res.data.id,
                  userType: res.data.userType,
                  periodId: res.data.periodId
                }).then((resdata) => {
                  this.dataTree = resdata.data.data;
                  this.checkedKeys = resdata.data.defaultCheckedKeys;
                  this.expandedKeys = resdata.data.defaultExpandedKeys;
                });
                
                if (this.titleStatus==2) {
                  this.getRepeated().then(data => {
                    this.form.userIds = data ? res.data.userIds : [];
                    
                    if (data) {
                      arumTaskFindAddrByArumUsers(res.data.userIds).then((resList) => {
                        this.tableData = resList.data;
                      });
                    }
                  })
                }
                this.form.inspectorIds = res.data.inspectorIds;
                this.times = [res.data.startTime.replace(/\//g,'-'), res.data.endTime.replace(/\//g,'-')];
                this.form.startTime = res.data.startTime;
                this.form.endTime = res.data.endTime;
                this.form.id = res.data.id;
                // this.form.userIds = res.data.userIds;
              } else {
                this.form = res.data;
                this.form.startTime = this.formetTime(res.data.startTime);
                this.form.endTime = this.formetTime(res.data.endTime);
                arumTaskFindAddrByArumUsers(res.data.userIds).then((resList) => {
                  this.tableData = resList.data;
                });
              }
  
              // arumTaskFindAddrByArumUsers(res.data.userIds).then((resList) => {
              //   this.tableData = resList.data;
              // });
            });
          }
      });
    },
    formetTime(time) {
      let date = new Date(time);

      var fullYear = date.getFullYear();

      var month = date.getMonth() + 1;

      var _date = date.getDate();

      var hours = date.getHours();

      var minutes = date.getMinutes();

      var seconds = date.getSeconds();

      if (month < 10) {
        month = "0" + month;
      }

      if (_date < 10) {
        _date = "0" + _date;
      }

      let Str = fullYear + "/" + month + "/" + _date;

      return Str;
    },
    setDate(val) {
      this.form.startTime = this.times[0];
      this.form.endTime = this.times[1];
    },
    watchUser(row) {
      this.userDialog = true;
      this.addrId = row.addrId;
      this.loadList(1);
    },
    handleCheckChange(data, checked, indeterminate) {
      let list = this.$refs.dataTree.getCheckedNodes();
      this.tableDataNum = {};
      this.form.userIds = [];
      
      let arr = []
      list.forEach((el) => {
        if (el.type == 5 && !el.children) {
            arr.push(el)
          if (!this.tableDataNum[el.addrId]) {
            this.tableDataNum[el.addrId] = { userCount: 1, addrName: el.addrName };
          } else {
            this.tableDataNum[el.addrId].userCount =
              parseInt(this.tableDataNum[el.addrId].userCount) + 1;
          }
          this.form.userIds.push(el.trueId.toString());
        }
      });
      console.log(arr)
      this.tableData = Object.values(this.tableDataNum);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.form.templateName == "") {
            this.$message.error("请先添加模板");
            return;
          }
          arumTaskTaskInsertOrUpdate(this.form).then((res) => {
            if (res.code == "200") {
              this.$message.success("保存成功");
              this.close();
            } else {
              this.$message.error(this.msg);
            }
          });
        }
      });
    },
    close() {
      this.form = {
        taskName: "", //任务名称
        inspectorIds: [], //安检员id数组
        scuTplId: "", //安检模板id
        tplId: "", //采集模板id
        userIds: "", //用户id数组
        userType: "",
        startTime: "", //任务时间(开始时间)
        endTime: "", //任务时间(结束时间)
      };
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
      this.checkList = [];
      this.colList = [];
      this.tableData = [];
      this.checkedKeys = [];
      this.expandedKeys = [];
      this.times = [];
      this.$emit("close");
    },
    closeUser() {
      this.tableDataUser = [];
    },
    /**
     * 更换条数
     */
    handleSizeChange(val) {
      this.search.current = 1;
      this.search.size = val;
      this.loadList();
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val) {
      this.search.current = val;
      this.loadList();
    },
    /**
     * 获取列表数据
     */
    loadList(page) {
      if (page) {
        this.search.current = 1;
      }
      this.search.addrId = this.addrId;
      this.search.taskId = this.form.id;
      arumTaskFindUsersByTaskIdAndAddrId(this.search).then((res) => {
        this.tableDataUser = res.data.records;
        this.total = res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.map-row {
  position: relative;
  .btn-draw {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.page {
  width: 99%;
  //   height: 100%;
  margin: 10px;
  height: calc(100vh - 220px);
  overflow: auto;
  background: #fff;
  padding: 0 15px 0 15px ;
  box-sizing: border-box;
  ::v-deep {
    // .el-form-item {
    // margin-bottom: 0;
    // }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;
    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }
  .box1 {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        padding: 10px;
        margin-bottom: 20px;
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.img {
  height: 12px;
  border: 1px solid #3f74d5;
  margin-top: 6px;
  margin-right: 5px;
}
.titleName {
  font-size: 18px;
  color: #252733;
  padding-bottom: 15px;
}
.addBtn {
  margin: 0 10px;
}

.informFlex {
  border: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  //   width: 80%;
  height: 400px;
  margin-top: 12px;
  border-radius: 4px;
  overflow: auto;
  ::v-deep .el-tree {
    // width: 30%;
    padding: 20px;
  }
  .table-contain {
    border-left: 1px solid #dcdfe6;
    width: 100%;
    padding: 10px 20px;
    span {
      font-size: 16px;
      color: #303133;
    }
  }
}
::v-deep .el-form-item {
  margin: 12px 0;
}

.watchForm {
  width: 70%;
  .el-form-item__label {
    color: #909399;
  }
  .el-form-item__content {
    color: #303133;
  }
}
</style>
